import styled from 'styled-components';

const Content = styled.section`
  margin: 0;
  width: 100%;
  height: ${({ height }) => height || 'auto'};
  background-color: ${({ bg }) => bg};
  padding: ${({ padding }) => padding};

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }
`;

export default Content;
