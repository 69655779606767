import React from 'react';
import styled from 'styled-components';
import { LinkedinSquare, FacebookSquare, Instagram } from '@styled-icons/boxicons-logos'

import { Container as Content, Row, Col } from 'reactstrap';
import { Description } from '../index';

const Facebook = styled(FacebookSquare)`
  color: #172B4D;
  width: 50px;
  height: 50px;
`

const InstagramIcon = styled(Instagram)`
  color: #172B4D;
  width: 50px;
  height: 50px;
`

const Linkedin = styled(LinkedinSquare)`
  color: #172B4D;
  width: 50px;
  height: 50px;
`

function FooterIndigina() {
  return (
    <div>
      <Container>
        <Content>
          <Row style={styleContainer} className="top-footer ">
            <Col className="flex-d-row justify-between">
              <div className="d-flex flex-d-row" style={{ alignItems: 'center' }}>
                <Description color="#172B4D" display="none">
                  Nos acompanhe nas redes sociais
                </Description>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href=" https://www.facebook.com/missaocovidbrasil/"
                  style={{ marginLeft: 20 }}
                >
                  <Facebook />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/missaocovid/"
                >
                  <InstagramIcon />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/miss%C3%A3o-covid"
                >
                  <Linkedin />
                </a>
              </div>
              <div className="d-flex align-center">
                <a
                  className="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:contato@missaocovid.com.br"
                >
                  contato@missaocovid.com.br
                </a>
              </div>
            </Col>
          </Row>
        </Content>
      </Container>
      <Rights>
        <Content style={{ color: '#172b4d' }}>
          <Row>
            <Col>
              <p className="footer">
                Missão Covid | Todos os direitos reservados - 2021
              </p>
            </Col>
          </Row>
        </Content>
      </Rights>
    </div>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  background-color: #f6f9fc;
`;

const Rights = styled.footer`
  width: 100%;
  height: 50px;
  background-color: #172b4d;
  position: absolute;
  display: flex;
  align-items: center;
`;

const styleContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export default FooterIndigina;
