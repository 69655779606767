import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

// Styles
import './style.scss';
import { Image, BoxData, Box, BoxTitle, BoxDesc, ImageTop } from './styles';

// Components
import {
  Title,
  SubTitle,
  Description,
  Content,
  Header,
  Button,
  Footer,
} from '../../components';

// ReactStrap
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
} from 'reactstrap';

// images
import imgOne from '../../assets/img/home/doctors.svg';
import imgThree from '../../assets/img/home/section-four.svg';
import imgFour from '../../assets/img/home/section-five.svg';

import imgMediaOne from '../../assets/img/home/media-one.png';
import imgMediaTwo from '../../assets/img/home/media-center.jpg';
import imgMediaThree from '../../assets/img/home/media-end.jpg';

function Home() {
  const { colors } = useContext(ThemeContext);
  window.scrollTo(0, 0);

  const handleRedirectToStarbem = () => {
    window.open('https://starbem.app/#baixar-aplicativo', '_blank');
  }

  return (
    <>
      <Header />
      <Content bg={colors.white} padding="80px 0 200px 0">
        <Container fluid="md">
          <Row className="d-flex justify-center column-screen">
            <Col className="d-flex align-center justify-center align-center-screen ">
              <ImageTop
                className="image-top"
                src={imgOne}
                alt="Assistência médica"
              />
            </Col>
            <Col className="justify-flex-end text-center-screen">
              <div className="title">
                <Title color={colors.primaryBlue}>
                  Encerramento das atividades
                </Title>
                <Description
                  color={colors.primaryBlue}
                  className="justify-center-screen"
                >
                  A Missão Covid atendeu gratuitamente pessoas com sintomas de COVID-19 por mais de dois anos graças ao trabalho incansável de médicos e outros profissionais voluntários. Agradecemos aos idealizadores, a todas as pessoas atendidas e a todos que tornaram possível este projeto solidário com divulgação, trabalho voluntário, parcerias e muito apoio!
                </Description>
                <Description
                  color={colors.primaryBlue}
                  className="justify-center-screen"
                >
                  As atividades do projeto foram encerradas, mas o nosso propósito de ajudar as pessoas a cuidar da saúde continua com a Starbem. Ao baixar o app e se cadastrar, você tem acesso a mais de 15 especialidades médicas, psicólogos e nutricionistas por um preço muito acessível.
                </Description>
              </div>
              <Button
                onClick={() => handleRedirectToStarbem()}
                style={{ fontWeight: 'bold' }}
              >
                Agendar consulta na Starbem
              </Button>
            </Col>
          </Row>
          <BoxData>
            <Container>
              <Box>
                <Col className="d-flex align-center justify-center flex-d-column">
                  <BoxTitle>+1.500</BoxTitle>
                  <BoxDesc>Médicos cadastrados</BoxDesc>
                </Col>
                <Col className="d-flex align-center justify-center flex-d-column">
                  <BoxTitle>+120.000</BoxTitle>
                  <BoxDesc>Pacientes atendidos</BoxDesc>
                </Col>
              </Box>
            </Container>
          </BoxData>
        </Container>
      </Content>
      <div style={{ paddingTop: 70 }} />
      <Content bg={colors.white} padding="80px 0 80px 0" />
      <Content bg={colors.gray100} padding="40px 0 40px 0">
        <Container>
          <Row className="mb-5">
            <Col className="object-remove">
              <Image
                src={imgThree}
                className="img"
                atl="Estou com alguns sintomas"
              />
            </Col>
            <Col>
              <SubTitle color={colors.primaryBlue} className="center">
                Estou com alguns sintomas
              </SubTitle>
              <Description color={colors.primaryBlue}>
                Se você está com sintomas leves como febre, tosse, perda do olfato, cansaço e/ou dor no corpo sem falta de ar, agende uma consulta gratuita por videochamada na Starbem. Permaneça em casa, desde que possa ficar adequadamente em isolamento e sob monitoramento e orientação médica a distância. Se você tem algum fator de risco para doenças mais graves, mesmo que apresente apenas sintomas leves, deve considerar ir ao hospital.
              </Description>
              <div
                className="d-flex"
              >
                <Button
                  width="50%"
                  className="justify-center-screen"
                  style={{ fontWeight: 'bold' }}
                  onClick={() => handleRedirectToStarbem()}
                >
                  Agendar consulta na Starbem
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Content>
      <Content bg={colors.white} padding="40px 0 40px 0">
        <Container>
          <Row className="mb">
            <Col className="mb-b justify-center-screen flex-d-col">
              <SubTitle color={colors.primaryBlue} className="center">
                Estou com sintomas graves
              </SubTitle>
              <Description color={colors.primaryBlue}>
                Em caso de sintomas mais graves, como falta de ar, sonolência
                excessiva ou dor no peito de forte intensidade, procure um
                serviço de emergência.
              </Description>
            </Col>
            <Col className="object-remove">
              <Image
                src={imgFour}
                className="img"
                alt="Estou com sintomas graves"
              />
            </Col>
          </Row>
        </Container>
      </Content>
      <Content bg={colors.gray100} padding="40px 0 40px 0">
        <Container>
          <Row className="mb-5 d-flex justify-center">
            <Title color={colors.primaryBlue}>Missão Covid na mídia</Title>
          </Row>
          <Row className="mb-5 flex-d-col">
            <Col className="margin-buttom">
              <Card className="border-none">
                <CardImg
                  src={imgMediaOne}
                  style={{ width: '100%', borderRadius: 0 }}
                />
                <CardBody>
                  <CardTitle className="card-title-media">
                    Médicos criam serviço de atendimento online gratuito na luta
                    contra o coronavírus
                  </CardTitle>
                  <CardText className="card-desc-media">
                    O serviço é reservado a pacientes que tenham sintomas da
                    Covid-19.
                  </CardText>
                  <a
                    href="https://g1.globo.com/jornal-nacional/noticia/2020/04/13/medicos-criam-servico-de-atendimento-online-gratuito-na-luta-contra-o-coronavirus.ghtml"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button>Abrir</Button>
                  </a>
                </CardBody>
              </Card>
            </Col>
            <Col className="margin-buttom">
              <Card className="border-none">
                <CardImg
                  src={imgMediaTwo}
                  style={{ width: '100%', borderRadius: 0 }}
                />
                <CardBody>
                  <CardTitle className="card-title-media">
                    Missão Covid: a iniciativa de médicos para atender por
                    telemedicina
                  </CardTitle>
                  <CardText className="card-desc-media">
                    Projeto gratuito dá o primeiro atendimento a pacientes ...
                  </CardText>
                  <a
                    href="https://veja.abril.com.br/saude/missao-covid-a-iniciativa-de-medicos-para-atender-por-telemedicina/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button>Abrir</Button>
                  </a>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="border-none">
                <CardImg
                  src={imgMediaThree}
                  style={{ width: '100%', borderRadius: 0 }}
                />
                <CardBody>
                  <CardTitle className="card-title-media">
                    Internet é problema no Brasil para telemedicina, diz
                    fundador da Missão Covid
                  </CardTitle>
                  <CardText className="card-desc-media">
                    Médico foi entrevistado no Ao Vivo em Casa, série de lives
                    da Folha
                  </CardText>
                  <a
                    href="https://www1.folha.uol.com.br/equilibrioesaude/2020/07/internet-e-problema-no-brasil-para-telemedicina-diz-fundador-da-missao-covid.shtml"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button>Abrir</Button>
                  </a>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Content>
      <Content
        bg={colors.primaryBlue}
        height="150px"
        className="d-flex align-center"
      >
        <Container className="d-flex justify-center">
          <Button>
            <Link to="/sobre-a-missao">Saiba mais sobre a Missão</Link>
          </Button>
        </Container>
      </Content>
      <Footer />
    </>
  );
}

export default Home;
