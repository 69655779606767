import styled from 'styled-components';

const SubTitle = styled.p`
  font-size: ${({ size }) => size || '2.6rem'};
  line-height: 6.5rem;
  font-weight: bold;
  color: ${({ color }) => color};
  margin-bottom: ${({ mb }) => mb};

  @media screen and (max-width: 600px) {
    font-size: 2rem;
  }

  @media (min-width: 600px) and (max-width: 992px) {
    line-height: 3rem;
  }
`;

export default SubTitle;
