import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

// Styles
import {
  Box,
  BoxSponsor,
  ImageSponsorOthers,
  styleCard,
  styleImg,
  styleSize,
  ImageSpon,
} from './styles';
import './styles.scss';

// Components
import {
  Title,
  Description,
  Content,
  Header,
  Footer,
  Volunteers,
} from '../../components/index';

// ReactStrap
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
} from 'reactstrap';

// image
import imgOne from '../../assets/img/Mission/founders/Kana.png';
import imgTwo from '../../assets/img/Mission/founders/Leandro.png';

import Gok from '../../assets/img/Mission/partners/gok.png';
import Zambon from '../../assets/img/Mission/partners/zambon.jpg';
import Starbem from '../../assets/img/Mission/partners/Starbem-logo.png';

import Abc from '../../assets/img/Mission/partners/abc.png';
import Age from '../../assets/img/Mission/partners/Ageimagem.jpeg';
import Anunciattho from '../../assets/img/Mission/partners/anunciattho.png';
import Erpflex from '../../assets/img/Mission/partners/erpflex.png';
import Helpvip from '../../assets/img/Mission/partners/helpvip.png';
import Itsseg from '../../assets/img/Mission/partners/itsseg.png';
import Kickante from '../../assets/img/Mission/partners/kickante.png';
import Velip from '../../assets/img/Mission/partners/velip.png';
import Statese from '../../assets/img/Mission/partners/statese.png';
import Gsaude from '../../assets/img/Mission/partners/gsaude.png';
import Elleet from '../../assets/img/Mission/partners/elleet.png';
import Fidalga from '../../assets/img/Mission/partners/fidalga.png';
import Life from '../../assets/img/Mission/partners/life.png';
import Sobral from '../../assets/img/Mission/partners/sobral.jpeg';
import Easysim4u from '../../assets/img/Mission/partners/Easysim4u.png';
import Hdi from '../../assets/img/Mission/partners/HDI.png';
import Onepc from '../../assets/img/Mission/partners/ONEPC.png';
import Xp from '../../assets/img/Mission/partners/xp.jpg';
import Lahar from '../../assets/img/Mission/partners/LAHAR.png';
import Claro from '../../assets/img/Mission/partners/claro.png';
import Clube from '../../assets/img/Mission/partners/clube-de-atores.svg';

function Mission() {
  const { colors } = useContext(ThemeContext);
  window.scrollTo(0, 0);

  return (
    <>
      <Header />
      <Content bg={colors.white} padding="40px 0 40px 0">
        <Container>
          <Row>
            <Col className="d-flex justify-center mb-5">
              <Title color={colors.primaryBlue}>A Missão Covid</Title>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-center flex-d-column">
              <Description className="d-flex justify-center text-center">
                A Missão Covid nasceu da ideia de 3 amigos antes do início da epidemia no Brasil, quando os primeiros casos ainda estavam começando. É uma startup sem fins lucrativos que levou atendimento médico 100% gratuito para os pacientes com sintomas da Covid-19 através da telemedicina.
              </Description>
              <Description className="d-flex justify-center text-center">
                O atendimento online realizado pela Missão Covid foi fundamental porque prestou os primeiros socorros aos suspeitos de estarem com a doença e distinguiu quem de fato deveria procurar um hospital, evitando assim mais contaminação e superlotação do sistema já saturado.
              </Description>
            </Col>
          </Row>
        </Container>
      </Content>
      <Content bg={colors.gray100} padding="40px 0 40px 0">
        <Container>
          <Row>
            <Col className="d-flex justify-center mb-5">
              <Title color={colors.primaryBlue} mb="20px">
                Quem somos
              </Title>
            </Col>
          </Row>
          <Row className="d-flex justify-center align-center flex-wrap">
            <Col className="d-flex justify-center mb-5 flex-wrap">
              <Card style={styleCard} className="card-respo ">
                <CardImg
                  className="d-flex mt-5"
                  style={styleImg}
                  src={imgOne}
                />
                <CardBody>
                  <CardTitle className="text-center" styles={styleSize}>
                    Cristiano Kanashiro
                  </CardTitle>
                  <p className="text-center p">CEO da GO-K</p>
                </CardBody>
              </Card>

              <Card style={styleCard}>
                <CardImg
                  className="d-flex mt-5"
                  style={styleImg}
                  src={imgTwo}
                />
                <CardBody>
                  <CardTitle className="text-center">Leandro Rubio</CardTitle>
                  <p className="text-center p">CEO da Starbem</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Content>
      <Volunteers />
      <Content bg={colors.white} padding="40px 0 40px 0">
        <Container>
          <Row>
            <Col className="d-flex justify-center mb-5">
              <Title color={colors.primaryBlue} mb="30px">
                Apoio
              </Title>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-center mobile-config">
              <Box>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://gok.digital/"
                >
                  <ImageSponsorOthers src={Gok} alt="GOK - Digital" />
                </a>
              </Box>
              <Box>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.zambonpharma.com/br/pt/"
                >
                  <ImageSpon src={Zambon} alt="Zambon" />
                </a>
              </Box>
              <Box>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://starbem.app/"
                >
                  <ImageSpon src={Starbem} alt="Starbem" />
                </a>
              </Box>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-center mb-5 flex-wrap">
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://abcomm.org/"
                >
                  <ImageSponsorOthers src={Abc} alt="AbComm" />
                </a>
              </BoxSponsor>

              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href='https://www.xpi.com.br/"'
                >
                  <ImageSponsorOthers src={Xp} alt="Xp Investimentos" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.anunciatthocomunicacao.com.br/"
                >
                  <ImageSponsorOthers src={Anunciattho} alt="Anunciattho" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.erpflex.com.br/"
                >
                  <ImageSponsorOthers src={Erpflex} alt="ErpFlex" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.startse.com/"
                >
                  <ImageSponsorOthers src={Statese} alt="Startse" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.institutoclaro.org.br/"
                >
                  <ImageSponsorOthers src={Claro} alt="Instituto Claro" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://segurosinteligentes.com.br/"
                >
                  <ImageSponsorOthers src={Itsseg} alt="Seguros Inteligentes" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.kickante.com.br/"
                >
                  <ImageSponsorOthers src={Kickante} alt="Kickante" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.velip.com.br/"
                >
                  <ImageSponsorOthers src={Velip} alt="Velip" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://helpvip.com.br/"
                >
                  <ImageSponsorOthers src={Helpvip} alt="helpvip" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://gsaudemarketing.com.br/"
                >
                  <ImageSponsorOthers src={Gsaude} alt="Dsaúde" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://elleetlui.com.br/"
                >
                  <ImageSponsorOthers src={Elleet} alt="Elleet Lui" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.ageimagem.com.br/"
                >
                  <ImageSponsorOthers src={Age} alt="Ageimagem" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.escovasfidalga.com.br/"
                >
                  <ImageSponsorOthers src={Fidalga} alt="Fidalga" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://lftm.com.br/"
                >
                  <ImageSponsorOthers src={Life} alt="Life" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://lftm.com.br/"
                >
                  <ImageSponsorOthers src={Sobral} alt="Advogacia Sobral" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.easysim4u.com/"
                >
                  <ImageSponsorOthers src={Easysim4u} alt="Easysim4u" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.hdiseguros.com.br/"
                >
                  <ImageSponsorOthers src={Hdi} alt="Hdi Seguros" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://n1it.com.br/homepage/"
                >
                  <ImageSponsorOthers src={Onepc} alt="Onepc" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.lahar.com.br/"
                >
                  <ImageSponsorOthers src={Lahar} alt="Lahar" />
                </a>
              </BoxSponsor>
              <BoxSponsor>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://clubedeautores.com.br/"
                >
                  <ImageSponsorOthers src={Clube} alt="Clube de Atores" />
                </a>
              </BoxSponsor>
            </Col>
          </Row>
        </Container>
      </Content>
      <Footer />
    </>
  );
}

export default Mission;
