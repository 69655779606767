import { concat } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import Cookies from 'js-cookie';

let authToken = null;

// Create an http link:
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_URL_API,
});

const authMiddleware = setContext((_, { headers }) => {
  const token = window.sessionStorage.getItem("token");

  if (token) {
    return {
      headers: {
        authorization: token,
      },
    };
  }
});

export const setToken = async (token, refreshToken, user, userType) => {
  try {
    authToken = token ? token : null;
    window.sessionStorage.setItem("token", token);
    window.sessionStorage.setItem("refreshToken", refreshToken);
    window.sessionStorage.setItem("auth", "true");

    if (userType) {
      window.sessionStorage.setItem("userType", userType);
    }

    const dataUser = JSON.stringify(user);
    Cookies.set("paciente", dataUser, {
      expires: 1
    });
  } catch (error) {
    console.log(error);
  }
};

export const setTokenIndian = async (token, refreshToken, user, userType) => {
  window.sessionStorage.clear();

  try {
    authToken = token ? token : null;
    window.sessionStorage.setItem("token", token);
    window.sessionStorage.setItem("refreshToken", refreshToken);
    window.sessionStorage.setItem("authIndian", "true");

    if (userType) {
      window.sessionStorage.setItem("userType", userType);
    }

    const dataUser = JSON.stringify(user);
    Cookies.set("user", dataUser, {
      expires: 1
    });
  } catch (error) {
    console.log(error);
  }
}

export const setTokenInRequest = async (token) => {
  try {
    authToken = token ? token : null;
  } catch (error) {
    console.log(error);
  }
};

export const destroyToken = async () => {
  try {
    window.sessionStorage.clear();
    Cookies.remove("paciente");
    authToken = null;
  } catch (error) {
    console.log(error);
  }
};

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

export default client;
