import React from 'react';
import { Link } from 'react-router-dom';

// Components
import {
  Menu,
  MenuList,
  ItemList,
  MyHome,
  MyGlobeAmericas,
} from './styles';
import { Button, Description } from '../index';

import './styles.scss';

function MenuWeb() {
  return (
    <Menu>
      <MenuList>
        <ItemList>
          <Link
            to="/"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MyHome />
            <Description>Home</Description>
          </Link>
        </ItemList>
        <ItemList>
          <Link
            to="/sobre-a-missao"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MyGlobeAmericas />
            <Description>Sobre a Missão</Description>
          </Link>
        </ItemList>
        {/* <ItemList>
          <Link
            to="/oque-e-covid-19"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MyQuestionMarkCircle />

            <Description>O que é COVID-19</Description>
          </Link>
        </ItemList> */}
      </MenuList>
      <div className="buttons">
        <Button className="mr-10 menu">
          <a
            target="_blank"
            href="https://starbem.app"
            rel="noopener noreferrer"
            style={{ color: '#FF6B19', fontWeight: 'bold' }}
          >
            {' '}
            Conheça a Starbem
          </a>
        </Button>
      </div>
    </Menu>
  );
}

export default MenuWeb;
