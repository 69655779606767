import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primaryBlue};
`;
export const Rights = styled.footer`
  width: 100%;
  height: 5rem;
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  margin-left: 2rem;
  width: 4rem;
  margin-top: 1.4rem;
`;

export const styleContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};
