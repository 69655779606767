import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Components
import {
  Container,
  Logo,
  ItemList,
  MyMenu,
  styleContainer,
  WrapperMenu,
  MyHome,
  MyGlobeAmericas,
} from './styles';
import { Container as Content } from 'reactstrap';
import { Button, Description, MenuWeb } from '../index';

import './styles.scss';

// Images
import logoImg from '../../assets/img/logo/logo.svg';

function Header() {
  const [open, setOpen] = useState('close');

  useEffect(() => {
    open === 'open'
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'initial');
  }, [open]);

  return (
    <Container>
      <Content style={styleContainer}>
        <Link to="/">
          <Logo src={logoImg} alt="Missão Covid" />
        </Link>

        <MyMenu className="menu-section">
          <div className="header">
            <Content
              style={styleContainer}
              className="d-flex justify-between align-center"
            >
              <div
                className="BurgerMenu__container"
                role="button"
                onClick={() => setOpen(open === 'open' ? 'close' : 'open')}
              >
                <i className={open}></i>
                <i className={open}></i>
                <i className={open}></i>
              </div>
            </Content>
          </div>
          <MenuWeb />
          {open === 'open' ? (
            <WrapperMenu className="BurgerMenu__menu">
              <ul id="menu-burger">
                <ItemList>
                  <Link
                    to="/"
                    onClick={() => setOpen(open === 'open' ? 'close' : '')}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <MyHome />
                    <Description>Home</Description>
                  </Link>
                </ItemList>
                <ItemList>
                  <Link
                    to="/sobre-a-missao"
                    onClick={() => setOpen(open === 'open' ? 'close' : '')}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <MyGlobeAmericas />
                    <Description>Sobre a Missão</Description>
                  </Link>
                </ItemList>
                {/* <ItemList>
                  <Link
                    to="/oque-e-covid-19"
                    onClick={() => setOpen(open === 'open' ? 'close' : '')}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <MyQuestionMarkCircle />

                    <Description>O que é COVID-19</Description>
                  </Link>
                </ItemList> */}
                <div className="buttons">
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                      <Button style={{ width: '100%' }} className="mr-10 menu">
                        <a
                          target="_blank"
                          href="https://starbem.app"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          Conheça a Starbem
                        </a>
                      </Button>
                    </div>
                </div>
              </ul>
            </WrapperMenu>
          ) : (
            ''
          )}
        </MyMenu>
      </Content>
    </Container>
  );
}

export default Header;
