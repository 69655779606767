import gql from 'graphql-tag'

export const PATIENT_NEW_PASS = gql`
  mutation NewPassword($password: String!) {
    newPasswordPaciente(password: $password) {
      ok
      message
      errors {
        message
      }
    }
  }
`
