import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

//Apollo
import { destroyToken } from '../../services/clientApollo';
import { showSuccess } from '../../utils/toasts';

// Components
import {
  Container,
  Logo,
  Menu,
  WrapperMenu,
  MenuList,
  ItemList,
  MyMenu,
  styleContainer,
  MyGlobeAmericas,
  MyQuestionMarkCircle,
  MyContactMail,
  Overlay,
  ModalWrapper,
  ButtonClose,
  InformationsContainer,
} from './styles';
import { Container as Content } from 'reactstrap';

//Component
import { Button, Description, Recovery, MenuAuth } from '../index';

//Styled
import './styles.scss';

// Images
import logoImg from '../../assets/img/logo/logo.svg';

function HeaderAuth() {
  const history = useHistory();
  const [recoveryShow, setRecoveryShow] = useState('none');
  const [open, setOpen] = useState('close');

  useEffect(() => {
    open === 'open'
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'initial');
  }, [open]);

  const handleShowModal = () => {
    setRecoveryShow('block');
  };

  const hideRecovery = () => {
    setRecoveryShow('none');
  };

  const logout = () => {
    destroyToken();
    history.replace('/paciente');
    showSuccess('Você foi desconectado com sucesso.');
  };

  return (
    <>
      <Container>
        <Content style={styleContainer}>
          <NavLink to="/">
            <Logo src={logoImg} alt="Missão Covid" />
          </NavLink>

          <MyMenu className="menu-section">
            <Content
              style={styleContainer}
              className="d-flex justify-between align-center"
            >
              <div
                className="BurgerMenu__container"
                role="button"
                onClick={() => setOpen(open === 'open' ? 'close' : 'open')}
              >
                <i className={open}></i>
                <i className={open}></i>
                <i className={open}></i>
              </div>
            </Content>
            <MenuAuth logout={logout} handleShowModal={handleShowModal} />
            {open === 'open' ? (
              <WrapperMenu className="BurgerMenu__menu">
                <ul id="menu-burger">
                  <ItemList>
                    <NavLink
                      activeClassName="active"
                      to="/dashboard"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <MyGlobeAmericas />
                      <Description>Dashboard</Description>
                    </NavLink>
                  </ItemList>
                  <ItemList>
                    <NavLink
                      activeClassName="active"
                      to="/consultas"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <MyQuestionMarkCircle />

                      <Description>Ver consultas</Description>
                    </NavLink>
                  </ItemList>
                  <ItemList
                    onClick={() => handleShowModal()}
                    style={{ cursor: 'pointer', marginTop: -3 }}
                  >
                    <MyContactMail />
                    <Description>Alterar senha</Description>
                  </ItemList>
                  <div className="buttons">
                    <Button
                      style={{ width: '100%', zIndex: 999 }}
                      className="menu"
                      color="primary"
                      onClick={() => logout()}
                    >
                      Sair
                    </Button>
                  </div>
                </ul>
              </WrapperMenu>
            ) : (
              ''
            )}
          </MyMenu>
        </Content>
      </Container>
      <>
        <Overlay style={{ display: recoveryShow }} />
        <ModalWrapper style={{ display: recoveryShow }}>
          <ButtonClose onClick={() => hideRecovery()} />
          <InformationsContainer>
            <Recovery closeModal={hideRecovery} />
          </InformationsContainer>
        </ModalWrapper>
      </>
    </>
  );
}

export default HeaderAuth;
