import React from 'react';
import { NavLink } from 'react-router-dom';

// Components
import {
  Menu,
  MenuList,
  ItemList,
  MyHome,
  MyContactMail,
  MyGlobeAmericas,
  MyQuestionMarkCircle,
} from './styles';
import { Button, Description } from '../index';

import './styles.scss';

// Imag

function MenuAuth({ logout, handleShowModal }) {
  const auth = window.sessionStorage.getItem('auth');

  return (
    <Menu className="menu">
      <MenuList>
        <ItemList>
          <NavLink
            activeClassName="active"
            to="/dashboard"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MyGlobeAmericas />
            <Description>Dashboard</Description>
          </NavLink>
        </ItemList>
        <ItemList>
          <NavLink
            activeClassName="active"
            to="/consultas"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MyQuestionMarkCircle />

            <Description>Ver consultas</Description>
          </NavLink>
        </ItemList>
        <ItemList
          onClick={handleShowModal}
          style={{ cursor: 'pointer', marginTop: -3 }}
        >
          <MyContactMail />
          <Description>Alterar senha</Description>
        </ItemList>
      </MenuList>
      <div className="buttons">
        <Button className="menu" color="primary" onClick={logout}>
          Sair
        </Button>
      </div>
    </Menu>
  );
}

export default MenuAuth;
