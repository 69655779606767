import React, { useState, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'

// Reacttrap
import { Container, Row, Col, FormGroup, Input } from 'reactstrap'

// Components
import { Content, Title, Button } from '../index'

// Styled
import { Image } from './styles'

// Scss
import './styles.scss'

import contactImg from '../../assets/img/contact/contact.png'

function Contact() {
  const { colors } = useContext(ThemeContext)
  const [state, setState] = useState({})

  const handleSendContact = () => {
    if (state.name && state.email && state.subject && state.message) {
      confirmAlert({
        title: 'Tudo certo!',
        message:
          'Sua mensagem foi enviada com sucesso, em breve entraremos em contato!',
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
              setState({})
            }
          }
        ]
      })
    } else {
      toast.error('Preencha todos os campos para continuar!', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }

  return (
    <Content>
      <Container className="d-flex justify-center flex-d-column">
        <Row className="d-flex justify-center mt-5">
          <Title color={colors.primaryBlue}>Fale Conosco</Title>
        </Row>
        <Row className=" mt-5 mb-5 convert">
          <Col className=" " md={6}>
            <FormGroup>
              <label
                className="form-control-label cl"
                htmlFor="input-username"
              >
                Nome
                </label>
              <Input
                className="form-control-alternative"
                type="text"
                style={{
                  height: 40,
                  fontSize: '1.2rem'
                }}
                value={state?.name || ''}
                onChange={e => setState({ ...state, name: e.target.value })}
              />
            </FormGroup>
            <FormGroup>
              <label className="form-control-label cl" htmlFor="input-email">
                E-mail
                </label>
              <Input
                className="form-control-alternative"
                type="email"
                style={{
                  height: 40,
                  fontSize: '1.2rem'
                }}
                value={state?.email || ''}
                onChange={e => setState({ ...state, email: e.target.value })}
              />
            </FormGroup>
            <FormGroup>
              <label
                className="form-control-label cl"
                htmlFor="input-assunto"
              >
                Assunto
                </label>

              <Input
                className="form-control-alternative"
                type="text"
                style={{
                  height: 40,
                  fontSize: '1.2rem'
                }}
                value={state?.subject || ''}
                onChange={e =>
                  setState({ ...state, subject: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup className="mb-5">
              <label
                className="form-control-label cl"
                htmlFor="input-mensage"
              >
                Mensagem
                </label>
              <Input
                className="form-control-alternative "
                type="textarea"
                style={{
                  height: 40,
                  fontSize: '1.2rem',
                  resize: 'none'
                }}
                value={state?.message || ''}
                onChange={e =>
                  setState({ ...state, message: e.target.value })
                }
              />
            </FormGroup>
            <Button color="primary" onClick={() => handleSendContact()}>
              Enviar
              </Button>
          </Col>
          <Col md={6} className="col-img">
            <Image src={contactImg} alt="Contact" className="img" />
          </Col>
        </Row>
      </Container>
    </Content>
  )
}

export default Contact
