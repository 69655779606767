import styled from 'styled-components';
import { CalendarCheck } from '@styled-icons/boxicons-solid/CalendarCheck';
import { Documents } from '@styled-icons/entypo/Documents';
import { LockPassword } from '@styled-icons/remix-fill/LockPassword';
import { Close } from '@styled-icons/material-rounded/Close';

export const Container = styled.header`
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray100};
`;

export const Logo = styled.img`
  width: 200px;
  height: 90px;
`;

export const Menu = styled.nav`
  display: flex;
  align-items: center;
  height: 10rem;
  justify-content: center;

  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

export const MenuList = styled.ul`
  margin-bottom: 0;
  display: flex;
  justify-content: flex-start;
`;

export const ItemList = styled.li`
  display: inline;
  padding: 0 1.3rem;
  text-decoration: none;

  @media (max-width: 600px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray500};
  }
`;

export const MyMenu = styled.nav``;

export const MyGlobeAmericas = styled(CalendarCheck)`
  display: none;

  @media (max-width: 600px) {
    display: initial;
    width: 3rem;
    margin-right: 1rem;
  }
`;

export const MyContactMail = styled(LockPassword)`
  display: none;

  @media (max-width: 600px) {
    display: initial;
    width: 3rem;
    margin-right: 1rem;
  }
`;

export const MyQuestionMarkCircle = styled(Documents)`
  display: none;

  @media (max-width: 600px) {
    display: initial;
    width: 3rem;
    margin-right: 0.6rem;
  }
`;

export const InformationsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background: #fff;
  color: #000;
  padding: 3rem;
  @media screen and (min-width: 600px) {
    padding: 4rem;
    max-width: 50rem;
    max-height: 50rem;
    border-radius: 0.5rem;
  }
`;

export const Overlay = styled.span`
  width: 9999px;
  height: 9999px;
  background: rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 9999;
`;

export const ButtonClose = styled(Close)`
  position: fixed;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  color: #787878;
  &:hover {
    cursor: pointer;
  }
`;

export const styleContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const Wrapper = styled.section`
  width: 100%;
  margin: 0 auto;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  nav {
    display: flex;
    align-items: center;
    width: 40px;
    height: 4em;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 999;

    .BurgerMenu__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 999;

      i {
        background: var(--color-secondary);
        width: 32px;
        height: 4px;
        margin: 4px;
        border-radius: 2px;
        transition: all ease 0.5s;
      }

      .open:nth-child(1) {
        transform: rotate(45deg) translate(9px, 8px);
      }

      .open:nth-child(2) {
        opacity: 0;
      }

      .open:nth-child(3) {
        transform: rotate(-45deg) translate(9px, -8px);
      }

      .close:nth-child(1) {
        transform: rotate(0) translateY(0);
      }

      .close:nth-child(2) {
        opacity: 1;
      }

      .close:nth-child(3) {
        transform: rotate(0) translateY(0);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .btn-other {
      display: none;
    }
  }

  @media (min-width: 750px) {
    justify-content: space-between;

    nav {
      display: none;
    }
  }
`;

export const WrapperMenu = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  transition: all ease 5s;

  #menu-burger {
    padding: 40px;
    margin-top: 100px;
    width: 100vw;
    height: 100vh;

    a {
      font-size: 18px;
      margin: 10px;
    }
  }
`;
