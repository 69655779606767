export const styleCard = {
  border: 0,
  maxWidth: 176.5,
  width: '100%',
  maxHeight: 280,
  background: '#fff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '2.5px',
  // border: '1px solid #f1f1f1',
};

export const styleImg = {
  borderRadius: '50%',
  maxWidth: 120,
  width: '100%',
  maxHeight: 120,
  height: '100%',
};

export const stylesize = {
  fontSize: 15,
};
