import styled from 'styled-components'

const Description = styled.p`
  font-size: ${({ size }) => size || '1.5rem'};
  margin: 2rem 0;
  color: ${({ color }) => color || '#525f7f'};
  padding: ${({ pd }) => pd};
  font-weight: ${({ weight }) => weight || '300'};
  margin-top: ${({ top }) => top};
  margin-bottom: ${({ bottom }) => bottom};

  .active {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media screen and (max-width: 600px) {
    display: ${({ display }) => display};
    text-align: ${({ center }) => center || 'center'};
    margin-bottom: ${({ bottom }) => bottom};
  }

  @media screen and (max-width: 768px) {
    display: ${({ display }) => display};
  }
`

export default Description
