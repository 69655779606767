import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import Home from '../pages/Home';
import Mission from '../pages/Mission';

const Routes = () => {
  return (
    <Router>
      <AnimatedSwitch
        atEnter={{ opacity: 0 }}
        atLeave={{ opacity: 0 }}
        atActive={{ opacity: 1 }}
        className="switch-wrapper"
      >
        <Route exact path="/" component={Home} />
        <Route path="/sobre-a-missao" component={Mission} />
        {/* <Route path="/oque-e-covid-19" component={Covid} />
        <Route path="/paciente" component={Patient} />
        <Route path="/cadastro" component={Register} />
        <Route path="/login" component={Login} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/consultas" component={Consults} /> */}
        {/* <Route path="/informacoes" component={Question} /> */}
        {/* <Route path="/indigenas" component={TraditionalPeoples} />
        <Route path="/povos-tradicionais" component={TraditionalPeoples} />
        <Route path="/consulta" component={DashboardIndigina} /> */}
      </AnimatedSwitch>
    </Router>
  );
};

export default Routes;
