import styled from 'styled-components';

export const ButtonDefault = styled.button`
  height: ${({ size }) =>
    size === 'sm' ? '3.1rem' : size === 'lg' ? '4.8rem' : '4rem'};
  background-color: ${({ theme }) => theme.colors.white};
  color: #ff6b19 !important;
  border: 0.1rem solid #ff6b19;
  border-radius: ${({ theme }) => theme.borderRadiusButton};
  text-align: center;
  font-size: 1.4rem;
  padding: 0 3rem;
  display: ${({ display }) => display};
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
  box-sizing: border-box;

  :disabled {
    cursor: no-drop;
    opacity: 0.5;
  }

  @media screen and (max-width: 600px) {
    width: ${({ width }) => width};
  }
`;

export const ButtonPrimary = styled(ButtonDefault)`
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  color: ${({ theme }) => theme.colors.white};
`;
