import React from 'react'

// Visual Styles
import { ButtonDefault, ButtonPrimary } from './styles'

function Button({ children, color, size, className, onClick, display, style, disabled }) {
  switch (color) {
    case 'primary':
      return (
        <ButtonPrimary
          onClick={onClick}
          size={size}
          style={style}
          disabled={disabled}
          className={className}
          display={display}
        >
          {children}
        </ButtonPrimary>
      )
    default:
      return (
        <ButtonDefault
          onClick={onClick}
          size={size}
          style={style}
          className={className}
          display={display}
          disabled={disabled}
        >
          {children}
        </ButtonDefault>
      )
  }
}

export default Button
