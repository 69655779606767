import styled from 'styled-components'

export const Image = styled.img`
  width: 100vw;
  max-width: 350px;

  @media (max-width: 600px) {
    max-width: 200px;
    height: 250px;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    width: 100%;
  }
`
