const config = {
  apiKey: 'AIzaSyD11wgiKTdV17sXiOaP2dSg6jFVJWO0D6A',
  authDomain: 'missao-covid.firebaseapp.com',
  databaseURL: 'https://missao-covid.firebaseio.com',
  projectId: 'missao-covid',
  storageBucket: 'missao-covid.appspot.com',
  messagingSenderId: '3407447941',
  appId: '1:3407447941:web:ca70f10eaa85ced48866ec',
  measurementId: 'G-8D2SKR6MS8',
};

export default config;
