import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 70%;
  }

  html,
  body {
    height: 100vh;
  }

  body {
    font-size: 1.4rem;
    overflow-x: hidden;
  }

  input {
    height: calc(1.5rem + 0.75rem + 6px);
  }

  a {
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: ${({ theme }) => theme.colors.primaryBlue};
  }

  a:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primaryLightBlue};
  }

/* img  */
  .img-black {
  filter: grayscale(100%);
}

.img-black:hover {
  filter: grayscale(0);
}

  /* Display  */

  .d-flex {
    display: flex;
  }

  /* Flex Direction  */

  .flex-d-row {
    flex-direction: row;
  }

  .flex-d-row-reverse {
    flex-direction: row-reverse
  }

  .flex-d-column {
    flex-direction: column;
  }

  .flex-d-column-reverse {
    flex-direction: column-reverse
  }

  /* Flex Wrap  */

  .flex-nowrap {
    flex-wrap: nowrap;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  /* Justify Content  */

  .justify-center {
    justify-content: center;
  }

  .justify-start {
    justify-content: flex-start;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .justify-around {
    justify-content: space-around;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-evenly {
    justify-content: space-evenly;
  }


  /* Align Items  */

  .align-center {
    align-items: center;
  }

  .align-start {
    align-items: flex-start;
  }

  .align-end {
    align-items: flex-end;
  }

  .align-baseline {
    align-items: baseline;
  }

  .align-stretch {
    align-items: stretch;
  }


  /* Text Align   */

  .text-center {
    text-align: center;
  }

  @media screen and (max-width: 57.6rem) {
    body {
      overflow: auto;
    }

    .object-remove {
      display: none;
    }

  }
`
