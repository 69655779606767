import React, { Suspense, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from 'reactstrap';
import firebase from 'firebase';
import { CloseCircleOutline } from '@styled-icons/evaicons-outline';

import config from './utils/firebase';

// Routes
import Routes from './Router';

// Global
import GlobalStyles from './styles/global';
import theme from './styles/theme';
import { StarbemWrapper } from './styles/Modal';

function App() {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const url = window.location.href;

    setTimeout(() => {
      if (!String(url).includes('povos-tradicionais')) setModal(true);
    }, 3000);
  }, []);

  useEffect(() => {
    firebase.initializeApp(config);
    firebase.analytics();
    firebase.performance();
  }, []);

  const toggle = () => setModal(!modal);

  const goToStarbem = () => {
    firebase.analytics().logEvent('go-to-starbem');
    window.open('https://starbem.app/#baixar-aplicativo', '_blank');
  };

  const _renderModal = () => (
    <Modal
      isOpen={modal}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CloseCircleOutline
        size={40}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          right: 0,
          margin: '10px',
          zIndex: 9,
        }}
        color="#000"
        onClick={() => toggle()}
      />
      <StarbemWrapper onClick={() => goToStarbem()} />
    </Modal>
  );

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Suspense fallback={<div>Carregando...</div>}>
        <Routes />
        {_renderModal()}
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
