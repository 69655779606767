/**************
 * Theme for Styled components
 * This can be used in your styled component to access default variables
 * Exemple:
 * background-color: ${props => props.theme.colors.primaryBlue}
 *************/

export default {
  colors: {
    white: '#FFFFFF',
    primaryBlue: '#172B4D',
    primaryPurple: '#5E72E4',
    primaryWhite: '#F4F5F7',
    primaryLightBlue: '#11CDEF',
    primaryGreen: '#2DCE89',
    primaryRed: '#F5365C',
    primaryOrange: '#FB6340',
    gray100: '#F6F9FC',
    gray200: '#E9ECEF',
    gray300: '#DEE2E6',
    gray400: '#CED4DA',
    gray500: '#ADB5BD',
    gray600: '#8898AA',
    gray700: '#525F7F',
    gray800: '#32325D',
    gray900: '#212529',
  },
  borderRadius: '8px',
  borderRadiusButton: '4px',
};
