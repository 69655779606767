import styled from 'styled-components'

export const ImageTop = styled.img`
  width: 100%;

  @media (min-width: 600px) and (max-width: 992px) {
    display: none;
  }
`

export const Image = styled.img`
  width: 100%;
  max-width: 500px;
  height: 100vh;
  max-height: 400px;
`

export const Description = styled.p`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.gray700};
  margin-top: 1.2rem;
  margin-bottom: 3rem;
`

export const DescriptionTwo = styled.p`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.primaryWhite};
  margin-top: 1.2rem;
  margin-bottom: 3rem;
  width: 55%;
  text-align: center;
`

export const BoxData = styled.div`
  width: 100%;
  height: 230px;
  position: absolute;
  left: 0;
  z-index: 10;
  margin-top: 8.5rem;
`

export const Box = styled.div`
  width: 100%;
  height: 230px;
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const BoxTitle = styled.h2`
  font-size: 6rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.gray100};

  @media (max-width: 600px) {
    font-size: 3.5rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.gray100};
  }
`

export const BoxDesc = styled.span`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.gray100};

  @media (max-width: 600px) {
    font-size: 1.2rem;
    text-align: center;
  }
`
