import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: auto;

  .recovery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .inputPassword {
    width: 100%auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .button {
    background: #172b4d;
    border: 1px solid #625d5d;
    box-sizing: border-box;
    border-radius: 4px;
    width: 156px;
    height: 40px;
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }
`
