import styled from 'styled-components';
import { Home } from '@styled-icons/icomoon';
import { GlobeAmericas } from '@styled-icons/fa-solid';
import { ContactMail } from '@styled-icons/material-rounded';
import { QuestionMarkCircle } from '@styled-icons/heroicons-solid';

export const Container = styled.header`
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray100};
`;

export const Logo = styled.img`
  width: 200px;
  height: 90px;
  z-index: 999;
`;

export const Menu = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

export const MenuList = styled.ul`
  margin-bottom: 0;
  display: flex;
  justify-content: flex-start;
`;

export const ItemList = styled.li`
  display: inline;
  padding: 0 1.3rem;
  text-decoration: none;

  @media (max-width: 600px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray500};
  }
`;

export const MyMenu = styled.nav`
  @media screen and (max-width: 600px) {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MyHome = styled(Home)`
  display: none;

  @media screen and (max-width: 600px) {
    display: initial;
    width: 3rem;
    margin-right: 1rem;
  }
`;

export const MyGlobeAmericas = styled(GlobeAmericas)`
  display: none;

  @media (max-width: 600px) {
    display: initial;
    width: 3rem;
    margin-right: 1rem;
  }
`;

export const MyContactMail = styled(ContactMail)`
  display: none;

  @media (max-width: 600px) {
    display: initial;
    width: 3rem;
    margin-right: 1rem;
  }
`;

export const MyQuestionMarkCircle = styled(QuestionMarkCircle)`
  display: none;

  @media (max-width: 600px) {
    display: initial;
    width: 3rem;
    margin-right: 0.6rem;
  }
`;

export const styleContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const Wrapper = styled.section`
  width: 100%;
  margin: 0 auto;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  nav {
    display: flex;
    align-items: center;
    width: 40px;
    height: 4em;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 999;

    .BurgerMenu__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 999;

      i {
        background: var(--color-secondary);
        width: 32px;
        height: 4px;
        margin: 4px;
        border-radius: 2px;
        transition: all ease 0.5s;
      }

      .open:nth-child(1) {
        transform: rotate(45deg) translate(9px, 8px);
      }

      .open:nth-child(2) {
        opacity: 0;
      }

      .open:nth-child(3) {
        transform: rotate(-45deg) translate(9px, -8px);
      }

      .close:nth-child(1) {
        transform: rotate(0) translateY(0);
      }

      .close:nth-child(2) {
        opacity: 1;
      }

      .close:nth-child(3) {
        transform: rotate(0) translateY(0);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .btn-other {
      display: none;
    }
  }

  @media (min-width: 750px) {
    justify-content: space-between;

    nav {
      display: none;
    }
  }
`;

export const WrapperMenu = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  transition: all ease 5s;

  #menu-burger {
    padding: 40px;
    margin-top: 100px;
    width: 100vw;
    height: 100vh;

    a {
      font-size: 18px;
      margin: 10px;
    }
  }
`;
