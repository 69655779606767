import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

// Components
import { Content, Title } from '../index';
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
} from 'reactstrap';

import { styleImg, styleCard, stylesize } from './style';

// Images
import Valeria from '../../assets/img/Mission/volunteers/Valeria.jpeg';
import Amanda from '../../assets/img/Mission/volunteers/Amanda.jpeg';
import Genesis from '../../assets/img/Mission/volunteers/Genesis.jpg';
import Renato from '../../assets/img/Mission/volunteers/Renato.jpg';
import Antonio from '../../assets/img/Mission/volunteers/Antonia.jpg';
import Carolina from '../../assets/img/Mission/volunteers/CarolinaOliveira.jpg';
import Caio from '../../assets/img/Mission/volunteers/Caio.jpeg';
import Caroline from '../../assets/img/Mission/volunteers/Caroline.jpg';
import Isabella from '../../assets/img/Mission/volunteers/Isabella.jpg';
import Patricis from '../../assets/img/Mission/volunteers/Patricia.jpg';
import Raquel from '../../assets/img/Mission/volunteers/Raquel.jpg';
import Ana from '../../assets/img/Mission/volunteers/Ana.jpg';
import Brendo from '../../assets/img/Mission/volunteers/Breno.jpg';
import Guilherme from '../../assets/img/Mission/volunteers/Guilherme.jpeg';
import Wilson from '../../assets/img/Mission/volunteers/Wilson.jpeg';
import Emerson from '../../assets/img/Mission/volunteers/Emerson.jpeg';
import Raul from '../../assets/img/Mission/volunteers/Raul.jpg';

function Volunteers() {
  const { colors } = useContext(ThemeContext);

  return (
    <Content bg={colors.white} padding="40px 0 40px 0">
      <Container className="d-flex flex-d-column justify-center mt-5">
        <Row>
          <Col className="d-flex justify-center mb-5">
            <Title color={colors.primaryBlue}>Voluntários</Title>
          </Col>
        </Row>
        <Row className="d-flex justify-center align-center flex-wrap">
          <Col
            className="d-flex justify-center mb-5 flex-wrap"
            style={{ display: 'flex', flexWrap: 'wrap' }}
          >
            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src="https://avatars0.githubusercontent.com/u/39813875?s=460&u=190efc0a2bcff9ef97430f01c69d767143b8a57a&v=4"
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Julio Augusto
                </CardTitle>
                <p className="text-center p">CTO da Starbem</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Ana}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Ana Bizzotto
                </CardTitle>
                <p className="text-center p">
                  Head de Comunicação na Starbem
                </p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Caio}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Caio Franchi
                </CardTitle>
                <p className="text-center p">
                  Head de Vendas na Starbem
                </p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src="https://avatars0.githubusercontent.com/u/43098770?s=460&u=95dfda776494bfb43ac130224f5918cb52140ed0&v=4"
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Davi Augusto
                </CardTitle>
                <p className="text-center p">Desenvolvedor na Signa</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Valeria}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Valeria Hevia
                </CardTitle>
                <p className="text-center p">UI/UX Designer na XP</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Amanda}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Amanda Barranco
                </CardTitle>
                <p className="text-center p">UI/UX Designer</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Raul}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Raul de Sousa
                </CardTitle>
                <p className="text-center p">Lead Designer na Gok</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Guilherme}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Guilherme Ramos
                </CardTitle>
                <p className="text-center p">Desenvolvedor na Starbem</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Wilson}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Wilson Junior
                </CardTitle>
                <p className="text-center p">Desenvolvedor na Gok</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Emerson}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Emerson Jesus
                </CardTitle>
                <p className="text-center p">Desenvolvedor na Gok</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Genesis}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Genesis Muniz
                </CardTitle>
                <p className="text-center p">Relacionamento Médico</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Renato}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Renato Zamboni
                </CardTitle>
                <p className="text-center p">Estágiario na Starbem</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Antonio}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Antonia Futuro
                </CardTitle>
                <p className="text-center p">Assessora de Imprensa</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Carolina}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Carolina Oliveira
                </CardTitle>
                <p className="text-center p">Assessora de Imprensa</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Caroline}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Caroline Teófilo
                </CardTitle>
                <p className="text-center p">Advogada</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Isabella}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Isabella Mendonça
                </CardTitle>
                <p className="text-center p">Assessora de Imprensa</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Patricis}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Patrícia Limeira
                </CardTitle>
                <p className="text-center p">Assessora de Imprensa</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Raquel}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Raquel Serafim
                </CardTitle>
                <p className="text-center p">Assessora de Imprensa</p>
              </CardBody>
            </Card>

            <Card style={styleCard}>
              <CardImg
                className="d-flex mt-5 img-black"
                style={styleImg}
                alt="Voluntarios"
                src={Brendo}
              />
              <CardBody>
                <CardTitle className="text-center" style={stylesize}>
                  Breno Dourado
                </CardTitle>
                <p className="text-center p">Acadêmico de Medicina</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Content>
  );
}

export default Volunteers;
