import styled from 'styled-components';
import starbem from '../assets/starbem/pop.png';

// Starbem
export const StarbemWrapper = styled.div`
  width: 603px;
  height: 636px;
  display: flex;
  background-image: url(${starbem});
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    width: 100%;
    background-size: cover;
  }
`;

export const StarbemImage = styled.img`
  position: relative;
  width: 100%;
  margin-top: 50px;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const StarbemLogo = styled.img`
  width: 180px;
  height: 60.9px;
  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    margin-top: 70px;
  }
`;

export const StarbemContent = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  margin-right: 20px;

  @media screen and (max-width: 600px) {
    width: auto;
    margin-top: -65px;
    margin-right: 0;
  }
`;

export const StarbemSpan = styled.span`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #2c2b43;
  padding: 10px 0;

  @media screen and (max-width: 600px) {
    text-align: center;
    padding: 15px 0;
    font-size: 16px;
  }
`;

export const StarbemText = styled.p`
  font-family: Mulish;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  width: 150px;
  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    width: 100%;
    text-align: center;
    padding: 0 0 15px 0;
    font-size: 16px;
  }
`;

export const StarbemLink = styled.a`
  font-family: Mulish;
  width: 200px;
  height: 40px;
  background: #ff3f72;
  border: 1px solid #ff3f72;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(255, 63, 114, 0.2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;

  p {
    font-family: Mulish;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;

    /* Interface/White */

    color: #ffffff;
    margin: 0;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    height: 40px;
  }
`;
