import React from 'react'
import styled from 'styled-components'
import Loader from 'react-loader-spinner'

function Loading() {
  return (
    <Container>
      <Loader type="Puff" color="#172B4D" height={100} width={100} />
      <h3 style={{ color: '#172B4D', paddingTop: 10 }}>Carregando...</h3>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export default Loading
