import React from 'react';
import styled from 'styled-components';

import headerImg from '../../assets/indigina/header.svg';
import logoImg from '../../assets/logo/logo.svg';

import { Container as Content, Button } from 'reactstrap';

function HeaderIndigina({ auth, logout }, props) {
  return (
    <Container>
      <Content>
        <Base>
          <Left>
            <Logo src={logoImg} alt="Missão Covid" />
          </Left>
          {auth && (
            <div>
              <Button
                style={{ backgroundColor: '#172B4D', width: 80, height: 40 }}
                onClick={() => logout()}
              >
                Sair
              </Button>
            </div>
          )}
        </Base>
      </Content>
    </Container>
  );
}

const Container = styled.header`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  background-image: url(${headerImg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f6f9fc;

  @media screen and (max-width: 600px) {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

const Base = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  width: 200px;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 600px) {
    width: auto;
  }
`;

const Logo = styled.img`
  width: 100%;

  @media screen and (max-width: 600px) {
    width: 150px;
  }
`;

export default HeaderIndigina;
