import styled from 'styled-components';

export const Image = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
`;

export const Box = styled.div`
  max-width: 180px;
  height: 110px;
  border-radius: 8px;
  /* background-color: #000; */
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    height: 110px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const BoxSponsor = styled.div`
  max-width: 140px;
  height: 110px;
  border-radius: 8px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center !important;
  align-items: center;

  a {
    display: flex;
    justify-content: center !important;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    max-width: 180px;
    width: 43%;
    height: 110px;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const ImageSponsorOthers = styled.img`
  width: 80%;
  border-radius: 8px;
`;

export const ImageSpon = styled.img`
  width: 100%;
  border-radius: 8px;

  @media screen and (max-width: 600px) {
    max-height: 110px;
  }
`;

export const ColDropFun = styled.div`
  width: 210px;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
  }

  @media (min-width: 768px) and (max-width: 992px) {
    margin: 10px;
  }
`;

export const ColDrop = styled.div`
  width: 210px;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
  }

  @media (min-width: 768px) and (max-width: 992px) {
    margin: 10px;
  }
`;
export const styleCard = {
  border: 0,
  maxWidth: 300,
  width: '100%',
  maxHeight: 280,
  background: '#F6F9FC',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 30,
};

export const styleImg = {
  borderRadius: '50%',
  maxWidth: 120,
  width: '100%',
  maxHeight: 120,
  height: '100%',
};

export const styleSize = {
  fontSize: 18,
};
