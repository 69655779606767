import styled from 'styled-components';

const Title = styled.span`
  font-size: 3rem;
  line-height: 5.5rem;
  font-weight: bold;
  color: ${({ color }) => color};
  margin-bottom: ${({ mb }) => mb};

  @media screen and (max-width: 600px) {
    font-size: 3rem;
    line-height: 3.5rem;
    text-align: center;
  }
`;

export default Title;
