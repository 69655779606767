import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import styled from 'styled-components'
import { LinkedinSquare, FacebookSquare, Instagram } from '@styled-icons/boxicons-logos'

// Components
import { Content, styleContainer, Rights } from './styles'
import { Container, Row, Col } from 'reactstrap'
import './styles.scss'

const Facebook = styled(FacebookSquare)`
  color: #FFFF;
  width: 70px;
  height: 70px;
`

const InstagramIcon = styled(Instagram)`
  color: #FFF;
  width: 70px;
  height: 70px;
`

const Linkedin = styled(LinkedinSquare)`
  color: #FFF;
  width: 70px;
  height: 70px;
`

function Footer() {
  const { colors } = useContext(ThemeContext)

  return (
    <>
      <Content color={colors.gray100}>
        <Container>
          <Row style={styleContainer} className="top-footer">
            <Col className="flex-d-row justify-center">
              <div className="d-flex flex-d-row" style={{ alignItems: 'center' }}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href=" https://www.facebook.com/missaocovidbrasil/"
                  style={{ marginLeft: 20 }}
                >
                  <Facebook />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/missaocovid/"
                >
                  <InstagramIcon />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/miss%C3%A3o-covid"
                >
                  <Linkedin />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Content>
      <Rights>
        <Container color={colors.primaryBlue}>
          <Row>
            <Col>
              <p className="footer">
                Missão Covid | Todos os direitos reservados - 2021
              </p>
            </Col>
          </Row>
        </Container>
      </Rights>
    </>
  )
}

export default Footer
